import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog-17-1.png"
import image2 from "../../images/blog-17-2.png"

export default function FiveTipsToDitchYourBackPain(){
    return(
        <BlogPostOld
            src={image}
            title={"Why Working Out is Good For You"}
            summary={"What are the benefits of being physically active?"}
            date={"July 25, 2022"}
            author={"Dr. Donald Mull, DC"}
        >
<p style={{textAlign: 'center'}}><span style={{textDecoration: 'underline'}}><strong>Why Working Out is Good For You</strong></span></p>
        <p>What are the benefits of being physically active? For the most part, I think it is pretty safe to say we inherently know that exercising and being active is healthy. But in my experience with helping people become more confident and more physically active with less limitations, it has become clear to me that most people are paralyzed with uncertainty around this topic.</p>
        <p>I mean, I don’t blame them. I spend most of my day thinking about this and dedicate hours each day consuming information on this topic and I am still just scratching the surface.&nbsp;</p>
        <p>Do you need to exercise in a gym to be healthy? Do you need to be a runner or go on runs to have a healthy heart? How much exercise do you need to be healthy?</p>
        <p>There has been an incredible amount of resources poured into finding out questions in regards to physical activity including mass surveys including hundreds of thousands of people and studies that gather as many health points as possible of massive sample sizes in hopes to shed light on or discover any correlations/associations between health and physical activity.&nbsp;</p>
        <p>One of the most important steps to answering these questions is first to clearly define the topic of interest. With that being said, there are two things that I think are important to not only define but also make a distinction from one another; exercise and physical activity.&nbsp;</p>
        <p>According to the World Health Organization Physical activity refers to all movement including during leisure time, for transport to get to and from places, or as part of a person’s work. Exercise can be defined as activity requiring physical effort, carried out to sustain or improve health and fitness.&nbsp;</p>
        <p>The difference there is intention. Physical activity can be unorganized without any intention of positively influencing health or acquiring skill whereas exercise is intentional physical effort to yield a positive outcome on skill acquisition or health outcome.&nbsp;</p>
        <p>I think this is an important distinction because both are beneficial to your health. Many people become discouraged or even anxious when the emphasis of health is placed upon exercise without prioritizing general physical activity. Exercise can become the source of emotional stress if one struggles to find time, equipment or resources to exercise. In my opinion exercise should be used to make physical activity more enjoyable and accessible (both with confidence and physical capability).&nbsp;</p>
        <p>There is no need to put extra pressure on yourself to exercise every single day. If you are strength training every major muscle group 2x a week, this is GREAT. Now go enjoy the physical activities you have afforded yourself. If you like to workout more than that, GREAT.&nbsp;</p>
        <p>So, how much physical activity is necessary to be healthy?</p>
        <p>According to the <a href="https://pubmed.ncbi.nlm.nih.gov/33239350/" target="_blank" rel="noopener">World Health Organization</a>, all adults should undertake 150-300 min of moderate-intensity, or 75-150 min of vigorous-intensity physical activity, or some equivalent combination of moderate-intensity and vigorous-intensity aerobic physical activity, per week (2x a week being strength training of all major muscle groups). Among children and adolescents, an average of 60 min/day of moderate-to-vigorous intensity aerobic physical activity across the week provides health benefits. The guidelines recommend regular muscle-strengthening activity for all age groups.</p>
        <img src={image} alt="" />
        <p><strong>What are the benefits of Physical Activity?</strong></p>
        <p>The amount of positive effects of physical activity when compared to being sedentary is mind blowing. I won’t even waste my time attempting to convey the different ways exercise and physical activity positively influences our health… just let this list from the <a href="https://www.who.int/news-room/fact-sheets/detail/physical-activity" target="_blank" rel="noopener">World Health Organization</a> below sink in:</p>
        <p>Regular physical activity can:</p>
        <ul>
          <li>improve muscula and cardiorespiratory fitness;</li>
          <li>improve bone and functional health;</li>
          <li>reduce the risk of hypertension, coronary heart disease, stroke, diabetes, various types of cancer (including breast cancer and colon cancer), and depression;</li>
          <li>reduce the risk of falls as well as hip or vertebral fractures; and</li>
          <li>help maintain a healthy body weight.</li>
        </ul>
        <p><strong>In children and adolescents, physical activity improves:</strong></p>
        <ul>
          <li>physical fitness (cardiorespiratory and muscular fitness)</li>
          <li>cardiometabolic health (blood pressure, dyslipidaemia, glucose, and insulin resistance)</li>
          <li>bone health</li>
          <li>cognitive outcomes (academic performance, executive function)</li>
          <li>mental health (reduced symptoms of depression)</li>
          <li>reduced adiposity</li>
        </ul>
        <p><strong>In adults and older adults, higher levels of physical activity improves:</strong></p>
        <ul>
          <li>risk of all-cause mortality</li>
          <li>risk of cardiovascular disease mortality</li>
          <li>incident hypertension</li>
          <li>incident site-specific cancers (bladder, breast, colon, endometrial, oesophageal adenocarcinoma, gastric and renal cancers)</li>
          <li>incident type-2 diabetes</li>
          <li>prevents of falls&nbsp;</li>
          <li>mental health (reduced symptoms of anxiety and depression)</li>
          <li>cognitive health</li>
          <li>sleep</li>
          <li>measures of adiposity may also improve&nbsp;</li>
        </ul>
        <p><strong>For pregnant and postpartum women&nbsp;</strong></p>
        <p>Physical activity confers the following maternal and fetal health benefits: a decreased risk of:</p>
        <ul>
          <li>pre-eclampsia,&nbsp;</li>
          <li>gestational hypertension,&nbsp;</li>
          <li>gestational diabetes (for example 30% reduction in risk)&nbsp;</li>
          <li>excessive gestational weight gain,&nbsp;</li>
          <li>delivery complications&nbsp;</li>
          <li>postpartum depression</li>
          <li>newborn complications,&nbsp;</li>
          <li>and physical activity has no adverse effects on birthweight or increased risk of stillbirth.</li>
        </ul>
        <p>What are the costs of being inactive?</p>
        <p>If I told you all of these benefits and all you had to do was take a pill, they would fly off the shelf regardless of how much I told you they cost. The problem is, as a society we aren’t meeting these guidelines. According to the <a href="https://www.cdc.gov/nchs/fastats/exercise.htm" target="_blank" rel="noopener">Centers for Disease Control and Prevention</a>, 23.2% percent of adults aged 18 and over met the Physical Activity Guidelines for both aerobic and muscle-strengthening activity. <em>Less than 1 in 4 people!</em></p>
        <p><strong>The health risks of being sedentary</strong></p>
        <p>Below is a list of risks from the <a href="https://www.who.int/news-room/fact-sheets/detail/physical-activity" target="_blank" rel="noopener">World Health Organization</a>.</p>
        <p>In children and adolescents:&nbsp;</p>
        <ul>
          <li>increased adiposity (weight gain)</li>
          <li>poorer cardiometabolic health, fitness, behavioural conduct/pro-social behaviour</li>
          <li>reduced sleep duration</li>
        </ul>
        <p>In adults:</p>
        <ul>
          <li>all-cause mortality, cardiovascular disease mortality and cancer mortality</li>
          <li>incidence of cardiovascular disease, cancer and type-2 diabetes.&nbsp;</li>
        </ul>
        <p>Also, here is a great depiction of the correlation to negative health and physical inactivity. The list of unwanted health events is daunting.</p>
        <img src={image2} alt="" />
        <p>As if this isn’t enough, being sedentary comes with additional fiscal costs. According to the Lancet, physical inactivity cost health-care systems international $ (INT$) 53·8 billion worldwide in 2013, of which $31·2 billion was paid by the public sector, $12·9 billion by the private sector, and $9·7 billion by households. In addition, physical inactivity related deaths contribute to $13·7 billion in productivity losses, and physical inactivity was responsible for 13·4 million disability-adjusted life-years worldwide.</p>
        <p>In Conclusion</p>
        <p>Though we know that being active is good, the numbers tell us we aren’t doing it. This is why Kinetic Impact exists. To help people become a more confident version of themselves with less physical limitations. Our mission is to provide a positive experience with movement and support a positive behavior change that will encourage a longer and HEALTHIER life. We are here for you from pain consultation, 1-on-1 coaching, small group and larger group fitness. Let’s work together to keep you young and resilient for as long as possible. <a href="https://kineticimpact.janeapp.com/" target="_blank" rel="noopener">Click here to get started!</a></p>
        <p>Possible references:<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6347102/" target="_blank" rel="noopener">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6347102/</a></p>
        <p>Ding, Ding; Lawson, Kenny D; Kolbe-Alexander, Tracy L; Finkelstein, Eric A; Katzmarzyk, Peter T; van Mechelen, Willem; Pratt, Michael (2016). <em>The economic burden of physical inactivity: a global analysis of major non-communicable diseases. The Lancet, (), S014067361630383X–. </em>doi:10.1016/S0140-6736(16)30383-X&nbsp;</p>
        <p><a href="https://www.cdc.gov/physicalactivity/basics/pa-health/index.htm#:~:text=Being%20physically%20active%20can%20improve,activity%20gain%20some%20health%20benefits" target="_blank" rel="noopener">https://www.cdc.gov/physicalactivity/basics/pa-health/index.htm#:~:text=Being%20physically%20active%20can%20improve,activity%20gain%20some%20health%20benefits</a>.</p>
        <p><a href="https://www.cdc.gov/chronicdisease/resources/publications/factsheets/physical-activity.htm#:~:text=Not%20getting%20enough%20physical%20activity%20can%20lead%20to%20heart%20disease,cholesterol%2C%20and%20type%202%20diabetes" target="_blank" rel="noopener">https://www.cdc.gov/chronicdisease/resources/publications/factsheets/physical-activity.htm#:~:text=Not%20getting%20enough%20physical%20activity%20can%20lead%20to%20heart%20disease,cholesterol%2C%20and%20type%202%20diabetes</a>.</p>
        <p><span style={{color: '#212121'}}>Bull FC, Al-Ansari SS, Biddle S, Borodulin K, Buman MP, Cardon G, Carty C, Chaput JP, Chastin S, Chou R, Dempsey PC, DiPietro L, Ekelund U, Firth J, Friedenreich CM, Garcia L, Gichu M, Jago R, Katzmarzyk PT, Lambert E, Leitzmann M, Milton K, Ortega FB, Ranasinghe C, Stamatakis E, Tiedemann A, Troiano RP, van der Ploeg HP, Wari V, Willumsen JF. World Health Organization 2020 guidelines on physical activity and sedentary behaviour. Br J Sports Med. 2020 Dec;54(24):1451-1462. doi: 10.1136/bjsports-2020-102955. PMID: 33239350; PMCID: PMC7719906.</span></p>
        <p><a href="https://www.who.int/news-room/fact-sheets/detail/physical-activity" target="_blank" rel="noopener">https://www.who.int/news-room/fact-sheets/detail/physical-activity</a></p>

            </BlogPostOld>

    )
}